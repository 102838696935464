import { Component, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { FormGroup, FormControl, Validators  } from '@angular/forms';

@Component({
    selector: 'app-forget-password',
    templateUrl: '../views/forget-password.component.html',
    styleUrls: ['../styles/forget-password.component.css'],
})
export class ForgetPasswordComponent implements OnInit {

  displayForgetPassword = true;
  emailValue;


  constructor(private router: Router,
      private auth: AuthService) {


  }

  ngOnInit() {


  }

  userEmail = new FormGroup({
    email: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
  });

  getEmail() {
    console.error(this.emailValue);
    this.displayForgetPassword = false;

    //Dergohet e dhana per forget password request
    this.auth.forgetPassword({ email: this.emailValue }).subscribe(response => {
      // console.error(response);
    });
  }

  get primEmail(){
    return this.userEmail.get('email')
  }

}
