import { Injectable, ErrorHandler } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { HttpHelper } from 'src/app/common';
import { Url } from '../../common/url';


export class Users {
  id: number;
  name: string;
  surname: string;
  email: string;
  password: string;
  role: string;
}

@Injectable()
export class AuthService {

  url = Url.apiUrl;

  constructor(private httpClient: HttpClient, private http: HttpHelper) {

  }

  forgetPassword(param): Observable<any> {
    return this.httpClient.post(this.url + '/signin/forget/password/users', param ).pipe(retry(3), catchError(this.handleError));
  }

  resetPassword(param): Observable<any> {
    return this.http.postResetPassword('/signin/reset/password/users', param ).pipe(retry(3), catchError(this.handleError));
  }

  postUser(param): Observable<any> {
    return this.httpClient.post(this.url + '/signup/users', param ).pipe(retry(3), catchError(this.handleError));
  }

  postUserToken(param): Observable<any> {
    return this.httpClient.post<Users>(this.url + '/signin/users/token', param ).pipe(retry(3), catchError(this.handleError));
  }

  getUser(param): Observable<any> {
    return this.httpClient.post<Users>(this.url + '/signin/users', param ).pipe(retry(3), catchError(this.handleError));
  }




 handleError(error: HttpErrorResponse) {
  let errorMessage = 'Unknown error!';
  if (error.error instanceof ErrorEvent) {
    // Client-side errors
    errorMessage = `Error: ${error.error.message}`;
  } else {
    // Server-side errors
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  window.alert(errorMessage);
  return throwError(errorMessage);
}



}
