import { Component, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-reset-password-token',
    templateUrl: '../views/reset-password-token.component.html',
    styleUrls: ['../styles/reset-password-token.component.css'],
})
export class ResetPasswordTokenComponent implements OnInit {

  private routeSub: Subscription;
  constructor(private router: Router,
      private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      console.log(params) //log the entire params object
      console.log(params['id']) //log the value of id
      localStorage.setItem('token', JSON.stringify(params['id']));
      this.router.navigate(['/reset-password']);
    });
  }


}
