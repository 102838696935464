import { NgModule ,LOCALE_ID, ErrorHandler} from '@angular/core';
import { CommonModule,registerLocaleData } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { PagesEventService } from './common/pages-event.service';
import { ServerErrorInterceptor } from './common/server-error.interceptor';
import { GlobalErrorHandler } from './common/error-handler';
import { HttpHelper } from './common/http-helper';



@NgModule({
    declarations: [

    ],
    imports: [

        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule
    ],
    providers: [
        HttpHelper,
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true }

    ],
    bootstrap: [],
})
export class SharedModule {

}

