import { Injectable } from '@angular/core';
import { 
  HttpEvent, HttpRequest, HttpHandler, 
  HttpInterceptor, HttpErrorResponse 
} from '@angular/common/http';
import { Observable, throwError,of } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    var authUser:any=localStorage.getItem("authUser");

    if(authUser != null)
    {
      authUser=JSON.parse(localStorage.getItem("authUser"));

      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${authUser.access_token}`,
        },
      });
    }
   

    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // refresh token
          return throwError(error);
        } 
        else {
          return throwError(error);
        }
      })
    );    
  }
}